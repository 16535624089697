import http from '@/utils/http'

// 设备报表-车辆报表-里程报表-分页
export const getMileageDataList = p => http.post('/material/reportManage/getMileageDataList', p)

// 设备报表-车辆报表-导出里程报表
export const exportMileageDataList = p => http.postFormExcel('/material/reportManage/exportMileageDataList', p)

// 设备报表-车辆报表-超速报表-分页
export const getOverSpeedDataList = p => http.post('/material/reportManage/getOverSpeedDataList', p)

// 设备报表-车辆报表-导出超速报表
export const exportOverSpeedDataList = p => http.postFormExcel('/material/reportManage/exportOverSpeedDataList', p)
